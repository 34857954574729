<script setup lang="ts">
import { DashboardLayout } from "aeria-app-layout";
import { AeriaSelect, AeriaIcon } from "aeria-ui";
import { hasActiveSubscription } from "../../../api/src/modules/subscription/hasActiveSubscription";

const breakpoints = useBreakpoints();

const userStore = useStore("user");
const organizationStore = useStore("organization");
const loginAs = ref<any>(null);
const organizations = ref<typeof organizationStore.item[]>([]);
const currentOrganization = ref<typeof organizationStore.item>({ name: "Nenhum" } as any)
const router = useRouter()
const metaStore = useStore('meta')

onMounted(async () => {
  const { result: myOrganizations }: any = await aeria.organization.getMyOrganizations.POST();
  organizations.value = myOrganizations
  currentOrganization.value = organizations.value.find(org => org?._id == userStore.currentUser.current_organization) || {
    name: "Nenhum"
  }
  status.value = hasActiveSubscription(currentOrganization.value) ? 'active' : 'inactive'

  const previous = JSON.parse(
    localStorage.getItem("aeria:previous-auth") ?? "[]"
  ) as Array<any>;
  if (previous.length > 0) {
    loginAs.value = previous[previous.length - 1].user;
  }
});

async function changeOrganization() {
  await aeria.user.insert.POST({
    what: {
      _id: userStore.currentUser._id,
      current_organization: currentOrganization.value?._id,
    },
  });

  await aeria.user.authenticate.POST({ revalidate: true });

  router.go(0);
}

async function logoutAs() {
  const previous = <any[]>JSON.parse(<string>localStorage.getItem("aeria:previous-auth"));
  const auth = previous.pop();

  localStorage.setItem("aeria:auth", JSON.stringify(auth));

  if (previous.length == 0) {
    localStorage.removeItem("aeria:previous-auth");
  } else {
    localStorage.setItem("aeria:previous-auth", JSON.stringify(previous));
  }
  
  router.push("/dashboard");
}

const status  = ref<'active' | 'inactive'>()
</script>

<template>
  <dashboard-layout>
    <template #super>
      <div class="tw-flex tw-flex-row tw-space-x-4 tw-max-w-80 tw-items-center">
        <span
        v-if="status"
        v-clickable
        @click="() => router.push('/dashboard/subscriptions')"
        :class="`
          badge
          ${status == 'active' ? '!tw-bg-green-600' : '!tw-bg-red-800'}
          tw-w-[8rem]
          tw-h-10
          tw-text-[10pt]
          tw-font-medium
          tw-mr-2
          tw-px-2.5
          tw-py-0.5
          tw-flex
          tw-justify-center
          tw-items-center
        `"
        >
          <aeria-icon class="tw-mr-1" v-if="status === 'active'" icon="calendar-check"></aeria-icon>
          <aeria-icon class="tw-mr-1" v-else icon="calendar-x"></aeria-icon>
          Plano {{ status == 'active' ? 'Ativo' : 'Inativo' }}
        </span>

        <div class="
            org
            tw-flex 
            tw-flex-row 
            tw-items-center 
            !tw-max-h-12
            tw-space-x-[-2pt]
            ">
            <aeria-icon
            @click="() => router.push('/dashboard/my-organization/')"
            icon="buildings" 
            class="
            org-icon
            tw-self-stretch
            tw-pl-2
            tw-pr-3
            tw-bg-[var(--theme-brand-color-shade-2)]
            "/>
            <aeria-select
            class="org-select"
            v-if="breakpoints.md"
            v-model="currentOrganization._id"
            :multiple="1"
            style="
              --background-color: var(--theme-brand-color-shade-2);
            "
            @change="changeOrganization">
            <option class="org-option" v-for="org in organizations" :value="org?._id">
              {{ org?.name }}
            </option>
          </aeria-select>
        </div>
      </div>

      <aeria-icon
        v-clickable
        icon="headphones"
        @click="router.push('/dashboard/support')"
      >
        <div class="tw-hidden xl:tw-inline">Suporte</div>
      </aeria-icon>

      <aeria-icon
        v-clickable
        v-if="metaStore.theme === 'dark'"
        icon="moon"
        @click="metaStore.theme === 'dark'
          ? metaStore.$actions.saveTheme('default')
          : metaStore.$actions.saveTheme('dark')
        "
        >
      </aeria-icon>
      <aeria-icon
        v-clickable
        v-else
        icon="sun"
        @click="metaStore.theme === 'dark'
          ? metaStore.$actions.saveTheme('default')
          : metaStore.$actions.saveTheme('dark')
        "
        >
      </aeria-icon>
    </template>

    <template #view-title>
      <div class="tw-w-full">
        <h1>{{ viewTitle }}</h1>

        <div v-if="loginAs" class=" tw-bg-red-100 tw-border tw-border-red-400 tw-text-red-700 tw-px-4 tw-py-3 tw-rounded" role="alert">
          Olá <strong class="tw-font-bold">{{ loginAs.given_name }}</strong>!
          Você está conectado como <strong class="tw-font-bold">{{ userStore.currentUser.name }}</strong>.
          Click <span class="tw-cursor-pointer tw-font-bold tw-underline" @click="logoutAs">aqui</span> para sair
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<style scoped>
.org-icon:hover {
  background-color: var(--theme-brand-color-shade-3);
  cursor: pointer;
}

.org-select:checked, .org-select:enabled, .org-select:active, .org-select:hover, .org-select {
  border: none;
  outline: none;
}

.badge:hover {
  filter: brightness(90%);
  -webkit-filter: brightness(90%);
}
</style>