import { createStore, createCollectionStore } from 'aeria-ui'

export const chat = createStore((context) => {
  const state = reactive({
    modal: false,
    taskModal: false,
    rawDescription: {
      search: {
        active: true,
        placeholder: 'Buscar por nome, telefone',
      },
      filters: [
        'closed',
        'tags',
        'attendant'
      ],
      filtersPresets: {
        active: {
          name: 'Ativos',
          default: true,
          filters: {
            closed: false,
          },
        },
        closed: {
          name: 'Finalizados',
          filters: {
            closed: true,
          },
        }
      },
      properties: {
        closed: {
          type: 'boolean',
        },
        tags: {
          type: 'array',
          items: {
            $ref: 'conversationTag',
            indexes: ['name'],
          },
          isReference: true,
          referencedCollection: 'conversationTag',
        },
        attendant: {
          $ref: 'user',
          indexes: ['name']
        }
      },
    },
    freshFilters: {
      tags: [],
    },
  })

  return createCollectionStore(
    {
      $id: 'chat',
      state,
      actions: (state) => ({
        async retrieveItems() {
          return aeria.funnelExecution.getChats.POST({
            pagination: {
              ...state.pagination,
            },
            filters: {
              ...state.$filters,
              ...state.filtersPreset,
            },
          })
        },
      }),
    },
    context,
  )
})
