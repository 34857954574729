import { createStore, createCollectionStore } from 'aeria-ui'

const WHATSAPP_API = 'https://api.whatsapp.com/send'

function whatsappLink(customerName: string, customerPhone: string) {
  return `${WHATSAPP_API}?phone=+${customerPhone}&text=Olá+${customerName}.`
}

export const funnelExecution = createStore((context) => {
  const state = reactive({
    modal: false,
  })

  return createCollectionStore(
    {
      $id: 'funnelExecution',
      state,
      actions: () => ({
        openWhatsappWeb: (execution: any) => {
          const link = whatsappLink(
            execution.customer_name,
            execution.customer_phone,
          )

          // @ts-ignore
          window.open(link, '_blank')
        },
        async finishChat(chatId: string) {
          const metaStore = useStore('meta', context.manager)
          await aeria.funnelExecution.finishChat.POST({
            chatId,
          })

          metaStore.$actions.spawnToast({
            text: 'Atendimento encerrado com sucesso',
          })
        },
        async reactiveChat(chatId: string) {
          const metaStore = useStore('meta', context.manager)
          await aeria.funnelExecution.reactiveChat.POST({
            chatId,
          })

          metaStore.$actions.spawnToast({
            text: 'Atendimento reativado com sucesso',
          })
        },
      }),
    },
    context,
  )
})
