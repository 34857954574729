<script setup lang="ts">
import { formatPhoneNumber, notNull } from "../../helpers";

definePage({
  meta: {
    title: "Atendimentos",
    icon: "chats",
    collection: "task",
  },
});

const funnelExecutionStore = useStore("funnelExecution");
const chatStore = useStore("chat");
const metaStore = useStore("meta");
const modal = ref(false);
const tagsPanel = ref(false);
const tagsChat = ref<any>({});
const router = useRouter()

const formData = ref<any>({});
const formSchema = {
  date_min: {
    type: "string",
    format: "date",
  },
  date_max: {
    type: "string",
    format: "date",
  },
};

const handleDownload = async () => {
  if (!notNull(formData.value.date_min) || !notNull(formData.value.date_max)) {
    metaStore.$actions.spawnToast({
      text: "Preencha as datas corretamente",
    });
    return;
  }

  try {
    const csv = await aeria.funnelExecution.downloadContacts.POST(formData.value);

    if (typeof csv !== "string") {
      metaStore.$actions.spawnModal({
        title: "Ops...",
        body: "Falha ao baixar contatos:" + csv.error.code
      });
      return
    }

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      new Date().toLocaleDateString() + "-contacts" + ".csv"
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err: any) {
    metaStore.$actions.spawnToast({
      text: err.toString(),
    });
  }
};

const goToChat = (chat: any) => router.push(`/dashboard/chat?e=${chat._id}`);
</script>

<template>
  <aeria-search select-only :key="tagsChat._id" v-model="tagsChat.tags" v-model:panel="tagsPanel" v-bind="{
    property: funnelExecutionStore.rawDescription.properties.tags,
    propertyName: 'tags'
  }" @update:model-value="funnelExecutionStore.$actions.deepInsert({
      what: funnelExecutionStore.$actions.select(['_id', 'tags'], tagsChat)
    })">
  </aeria-search>

  <aeria-crud collection="chat">
    <template #empty>
      <empty-component></empty-component>
    </template>

    <template #actions>
      <aeria-button @click="modal = true">Download contatos</aeria-button>
    </template>
    <template #component>
      <aeria-table>
        <template #thead>
          <tr>
            <th>Cliente</th>
            <th>Última mensagem</th>
            <th>Atualizado</th>
            <th>Tags</th>
            <th></th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="chat in chatStore.items" :key="chat._id">
            <td>
              <div class="tw-flex tw-flex-col tw-gap-1">
                <div>{{ chat.customer_name }}</div>
                <div class="tw-opacity-80 tw-text-[8.5pt]">
                  {{ formatPhoneNumber(chat.customer_phone) }}
                </div>
              </div>
            </td>
            <!-- <td>
                <status-badge :color="chat.chat_finished ? 'green' : 'blue'">
                  {{ chat.chat_finished ? "Sim" : "Não" }}
                </status-badge>
              </td> -->
            <td>{{ chat.last_message ?? "" }}</td>
            <td>{{ chat.updated_at || chat.created_at ? new Date(chat.updated_at ?? chat.created_at).toLocaleString() : "-" }}</td>
            <td>
              <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-1">
                <chat-tag v-for="tag in chat.tags" v-bind="tag" :key="`tag-${tag._id}-${chat._id}`" @click="
                  tagsPanel = true;
                tagsChat = chat;
                "></chat-tag>
                <aeria-button small v-if="chat.tags?.length === 0" icon="plus" variant="transparent" @click="
                  tagsPanel = true;
                tagsChat = chat;
                ">
                  Adicionar
                </aeria-button>
              </div>
            </td>
            <td>
              <div class="tw-flex tw-items-center tw-gap-1">
                <aeria-button small icon="phone" variant="transparent" @click="goToChat(chat)">
                  Chat
                </aeria-button>
                <!--
                <aeria-context-menu :actions="makeActions(chat)">
                  <aeria-icon
                    v-clickable
                    reactive
                    icon="dots-three-vertical"
                  ></aeria-icon>
                </aeria-context-menu> -->
              </div>
            </td>
          </tr>
        </template>
      </aeria-table>

    </template>
  </aeria-crud>

  <aeria-panel close-hint float title="Download" v-model="modal" @overlay-click="modal = false">
    <aeria-form v-model="formData" :form="formSchema" />
    <div class="tw-flex tw-justify-end">
      <aeria-button @click="handleDownload">Download</aeria-button>
    </div>
  </aeria-panel>
</template>
