<script lang="ts" setup>
import { STORE_ID } from "aeria-ui";
const funnelStore = useStore("funnel");
const metaStore = useStore("meta");

definePage({
  meta: {
    title: "Mensagens externas",
    icon: "chat"
  }
})

provide(STORE_ID, "funnel");

onMounted(async () => {
  const { result } = await aeria.organization.getExternalMessageFunnel.POST();
  funnelStore.$actions.setItem(result);
});

const handleSaveForm = async (messages: Array<any>) => {
  const { result } = await aeria.funnel.updateOrCreate.POST({
    funnel: {
      ...funnelStore.item,
      messages,
    },
  });

  funnelStore.$actions.setItem(result)

  metaStore.$actions.spawnToast({
    text: "Os dados foram salvos com sucesso",
  });
};

const handleSaveFlow = async (messages: Array<any>) => {
  const { result } = await aeria.funnel.updateOrCreate.POST({
    funnel: {
      ...funnelStore.item,
      messages,
    },
  });
  
  funnelStore.$actions.setItem(result);
};
</script>

<template>
  <funnel
    title="Funil de mensagens externas"
    :save-disabled="false"
    @save-form="handleSaveForm"
    @save-flow="handleSaveFlow"
    :disable-variables="true"
  >
  </funnel>
</template>
