import { createStore, createCollectionStore } from 'aeria-ui'

export const broadcast = createStore((context) => {
  const state = reactive({
    modal: false,
    taskModal: false,
  })

  return createCollectionStore(
    {
      $id: 'broadcast',
      state,
      actions: (state, actions) => ({
        closeModal() {
          state.modal = false
        },
        closeTaskModal() {
          state.taskModal = false
        },
        editBroadcast(item: any) {
          state.modal = true
          actions.setItem(item)
        },
        openModal() {
          state.modal = true
        },
        createTask(item: any) {
          state.taskModal = true
          actions.setItem(item)
        },
      }),
    },
    context,
  )
})
